<template>
  <nav class="navbar navbar-expand-lg">
    <a class="navbar-brand" href="#">
      <img src="../assets/logo.png" alt="Logo" class="logo" />
    </a>
    <button class="navbar-toggler" type="button" @click="toggleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" ref="navbarMenu">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/" exact-active-class="">Inicio</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contacto</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#groups">Grupos</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#footer">Nosotros</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent',
  methods: {
    toggleNavbar() {
      const navbar = this.$refs.navbarMenu;
      navbar.classList.toggle('show'); // Bootstrap usa la clase 'show' para colapsar
      document.body.classList.toggle('navbar-open'); // Add class to body when navbar is toggled
    }
  }
};
</script>


<style scoped>
nav {
  display: flex;
  align-items: center;
  padding: 10px 30px; /* Reduced padding to make the navbar thinner */
  background-color: #4b0082;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050; /* Increased z-index to ensure the navbar stays above other content */
}

nav .logo {
  height: 40px; /* Adjusted height for a smaller logo */
  margin-right: 20px;
  border-radius: 50%; /* Make the logo image round */
  object-fit: cover; /* Ensure the logo image is contained within the specified height */
}

nav a {
  font-weight: bold;
  color: #ffffff;
  margin: 0 10px;
}

nav a.router-link-exact-active {
  color: #369f6b;
}

nav a:hover {
  color: #369f6b;
}

/* Additional styles to adjust the main content when navbar is toggled */
body.navbar-open .main-content {
  margin-top: 200px; /* Adjust this value based on the height of your expanded navbar */
}
</style>