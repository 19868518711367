// filepath: e:\mundoEscort\frontend\src\components\FooterComponent.vue
<template>
  <footer class="footer mt-auto py-3 bg-light">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="social-icons">
            <a href="#"><i class="fab fa-x-twitter"></i></a> <!-- X (formerly Twitter) -->
            <a href="#"><i class="fab fa-tiktok"></i></a> <!-- TikTok -->
            <a href="https://www.youtube.com/channel/UCE81Ieviv4jhaY6VtSmlKKQ" target="_blank"><i class="fab fa-youtube"></i></a> <!-- YouTube -->
            <a href="mailto:contacto@mundoescort.cl"><i class="fas fa-envelope"></i></a> <!-- Gmail -->
            <a href="https://wa.me/56933093820?text=Hola%20me%20gustaría%20obtener%20más%20información" target="_blank"><i class="fab fa-whatsapp"></i></a> <!-- WhatsApp -->
            <a href="#"><i class="fab fa-telegram-plane"></i></a> <!-- Telegram -->
          </div>
        </div>
      </div> <!-- Added missing closing div tag -->
      <div class="row">
        <div class="col-12 text-center mt-3">
          <p>MUNDO INFORMATIVO(C)</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons a {
  margin: 0 10px;
  color: #000;
  font-size: 24px;
}

.social-icons a:hover {
  color: #007bff;
}

h5 {
  margin-bottom: 15px;
}

ul {
  padding: 0;
  list-style: none;
}

ul li {
  margin-bottom: 10px;
}
</style>