<template>
  <NavbarComponent class="fixed-navbar" />
  <div class="main-content">
    <router-view/>
  </div>
  <FooterComponent />
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  components: {
    NavbarComponent,
    FooterComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.main-content {
  padding-top: 80px; /* Adjust this value based on the height of your navbar */
  transition: margin-top 0.3s; /* Smooth transition for margin-top adjustment */
}

/* Ensure the main content adjusts when the navbar is toggled */
body.navbar-open .main-content {
  margin-top: 200px; /* Adjust this value based on the height of your expanded navbar */
}
</style>