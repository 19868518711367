import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Bootstrap JS is imported
import 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS

createApp(App).use(store).use(router).mount('#app')
