<template>
  <div class="home">
    <ContactForm id="contact" />
    <HelloWorld />
    <h1>Ingresa a Nuestros Grupos</h1>
    <div id="groups" class="groups row">
      <div class="group-card col-12 col-md-6 col-lg-4" v-for="group in groups" :key="group.id">
        <img :src="group.image" alt="Group Image" class="img-fluid" />
        <h2>{{ group.name }}</h2>
        <p>{{ group.description }}</p>
        <div class="button-container">
          <a :href="group.link" class="btn btn-primary btn-block" target="_blank">Unirse</a>
        </div>
      </div>
      
    </div>
    <footer id="footer">
      <!-- Footer content -->
    </footer>
    <button @click="scrollToTop" class="scroll-to-top">↑</button>
  </div>
</template>

<script>
import norteImg from '@/assets/norte.png';
import centroNorteImg from '@/assets/centro-norte.png';
import santiagoImg from '@/assets/santiago.png';
import centroSurImg from '@/assets/centro-sur.png';
import surImg from '@/assets/sur.png';
import funasImg from '@/assets/funas.png';
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import ContactForm from './ContactForm.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    ContactForm
  },
  data() {
    return {
      groups: [
        { id: 1, name: 'Angeles y Demonios ZONA NORTE', description: 'Este grupo incluye información de: Arica, Iquique, Alto Hospicio, Antofagasta, Calama, Tocopilla, Copiapó, Huasco, Vallenar y cercanos.', image: norteImg, link: 'https://chat.whatsapp.com/KEyzexXhP2B3x45Em3khO2' },
        { id: 2, name: 'Angeles y Demonios ZONA CENTRO NORTE', description: 'Este grupo incluye información de: La Serena, Coquimbo, Ovalle, Los Vilos, Valparaíso, Viña del Mar, Quilpue, Villa Alemana, Quillota, La Calera, Los Andes, San Antonio, San felipe y alrededores.', image: centroNorteImg, link: 'https://chat.whatsapp.com/FYRIsqa4xQ9J1GnmkAyMvb' },
        { id: 3, name: 'Angeles y Demonios SÓLO SANTIAGO', description: 'Este grupo incluye información de: Santiago, Providencia, Las Condes, Ñuñoa, Recoleta, Estación Central, La Cisterna, La Reina, San Bernardo, Maipú, Puente Alto, La Granja, Buin, Melipilla y todas las estaciones del metro.', image: santiagoImg, link: 'https://chat.whatsapp.com/C916qtKxi5l2VCaydgpBOT' },
        { id: 4, name: 'Angeles y Demonios ZONA CENTRO SUR', description: 'Este grupo incluye información de: Rancagua, San Fernando, Talca Linares Curico, Santa Cruz, Chillan, Concepción, Los Ángeles.', image: centroSurImg, link: 'https://chat.whatsapp.com/CQ1fHAgEqls7zd2pWs7nx1' },
        { id: 5, name: 'Angeles y Demonios ZONA SUR', description: 'Este grupo incluye información de: Temuco, Angol, Pucón, Villarrica, Valdivia Osorno, Puerto Montt, Chiloé, Coyhaique, Puerto Aysén, Punta Arenas, Puerto Natales y cercanos.', image: surImg, link: 'https://chat.whatsapp.com/HXD4GJk0ao4DTeRiGDnXgP' },
        { id: 6, name: 'Angeles y Demonios FUNAS CHILE', description: 'Este grupo incluye información de: Funas y reportes de clientes a nivel nacional, de Arica a Punta Arenas.', image: funasImg, link: 'https://chat.whatsapp.com/FTeRVuXz1xF6VTUvC31Llm' }
      ]
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.home {
  text-align: center;
}

.groups {
  margin: 20px 0;
}

.group-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.group-card h2 {
  margin: 10px 0;
}

.group-card p {
  color: #666;
}

.button-container {
  margin-top: auto; /* Push the button to the bottom */
}

.group-card a {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  width: 100%; /* Make the button occupy the maximum width */
  display: block; /* Ensure the button is a block element */
  margin-top: 10px; /* Add some margin to separate from the description */
}

.group-card a:hover {
  background-color: #369f6b;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.scroll-to-top:hover {
  opacity: 1;
}
</style>
