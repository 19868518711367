<template>
  <div class="next-services">
    <button class="btn btn-pri w-100" @click="showModal = true">SERVICIOS QUE PRÓXIMAMENTE OFRECEREMOS POR CIUDAD</button>
    <div v-if="showModal" class="modal-overlay" @click.self="showModal = false">
      <div class="modal-content container">
        <div class="modal-header">
          <button class="close-btn w-100" @click="showModal = false">Cerrar</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div v-for="image in images" :key="image.src" class="col-6 col-md-3 mb-3">
              <img :src="image.src" :alt="image.title" class="img-fluid" />
              <p class="text-center">{{ image.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      showModal: false,
      images: [
        { src: require('@/assets/icon1.png'), title: 'Hospedajes' },
        { src: require('@/assets/icon9.png'), title: 'Comida' },
        { src: require('@/assets/icon2.png'), title: 'Preservativos' },
        { src: require('@/assets/icon4.png'), title: 'Publicistas' },
        { src: require('@/assets/icon3.png'), title: 'Transporte' },
        { src: require('@/assets/icon5.png'), title: 'Fotógrafos' },
        { src: require('@/assets/icon6.png'), title: 'Estética' },
        { src: require('@/assets/icon7.png'), title: 'Páginas' },
        { src: require('@/assets/icon8.png'), title: 'Lencería' },
        { src: require('@/assets/icon11.png'), title: 'Farmacias' },
        { src: require('@/assets/icon12.png'), title: 'Emergencias', },
        { src: require('@/assets/icon10.png'), title: 'Gimnasios' }
      ]
    }
  }
}
</script>

<style scoped>
.btn-pri {
  background-color: #4b0082;
  color: white;
  padding: 30px;
  font-size: 24px; /* Increased text size */
  font-weight: bold; /* Make text bold */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051; /* Ensure the modal is above the navbar */
}

.modal-content {
  background: white;
  padding: 0;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  background: white;
  padding: 0;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 10;
}

.close-btn {
  background: red;
  color: white;
  border: none;
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
}

.modal-body {
  padding: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.grid-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.grid-item p {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}
</style>